//Types
export const chatMessage = 'chat_message'
export const chatMessageUpdated = 'chat_message_updated'
export const chatMessageRemoved = 'chat_message_removed'
export const conversationUserJoined = 'conversation_user_joined_chat'
export const userLogout = "user_logged_out"
export const userProfileUpdated = "user_profile_updated"

//Categories
export const chatRequestResponded = "chat_request_responded"
export const caseClosed = "case_closed"
export const caseArchived = "case_archived"
export const caseReopened = "case_reopened"