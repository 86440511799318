// ** Imports createContext function
import React, {createContext, useEffect, useState} from 'react'

// ** Imports createContextualCan function
import { createContextualCan } from '@casl/react'
import {useCurrentUser} from "./CurrentUserContext"
import {initialAbility} from "../../configs/acl/initialAbility"
import { Ability } from '@casl/ability'
import {userAbility} from "../../configs/acl/userAbility"

// ** Create Context
export const AbilityContext = createContext(null)

// ** Init Can Context
export const Can = createContextualCan(AbilityContext.Consumer)

export const AbilityContextProvider = ({ children }) => {
  const [ability, setAbility] = useState(new Ability(initialAbility))
  const currentUser = useCurrentUser()

  // Update ability on current user & current company update
  useEffect(() => {
    setAbility(new Ability(
        currentUser.uuid ? userAbility(currentUser) : initialAbility
    ))
  }, [currentUser.uuid, currentUser.roles])

  return (
      <AbilityContext.Provider value={ability}>
        {children}
      </AbilityContext.Provider>
  )
}
