// ** React Imports
import { useEffect, useState } from 'react'

// ** Custom Hooks
import { useSkin } from '@hooks/useSkin'
import FooterComponent from "./components/footer"

const BlankLayout = ({ children, footer, ...rest }) => {
  // ** Hooks
  const [skin, setSkin] = useSkin()

  // ** States
  const [isMounted, setIsMounted] = useState(false)

  //** ComponentDidMount
  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  // ** Vars
  const footerClasses = {
    static: 'footer-static',
    sticky: 'footer-fixed',
    hidden: 'footer-hidden'
  }

  if (!isMounted) {
    return null
  }

  return (
    <div className='blank-page h-100'>
      <div className='app-content content'>
        <div className='content-wrapper'>
          <div className='content-body'>{children}</div>
        </div>
      </div>
      <footer className='footer footer-light footer-static'>
        {footer ? footer : <FooterComponent/>}
      </footer>
    </div>
  )
}

export default BlankLayout
