import IntlDropdown from '../navbar/IntlDropdown'

const Footer = () => {
  return (
    <div className='clearfix mb-0'>
      <span className='float-md-left d-block d-md-inline-block mt-25 footer-font-size'>
        COPYRIGHT © {new Date().getFullYear()}{' '}WorkHub
        <span className='d-sm-inline-block'>, All rights Reserved</span>
      </span>
    </div>
  )
}

export default Footer
