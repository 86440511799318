const initialState = {
  request: false,
  dashboardCall: false
}

const JoinRequest = (state = initialState, action) => {
  switch (action.type) {
    case "Timeout_Request":
      return { ...state, request: true }

    case "Reset_Request":
      return { ...state, request: false }

    case 'SET_DASHBOARD_CALL': 
      return { ...state, dashboardCall: action.dashboardCall}
    default:
      return state
  }
}

export default JoinRequest