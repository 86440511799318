import {useState} from "react"
import scClient from "../../socketcluster/socketcluster"

export const useSocketCluster = () => {
  // todo cleanup is required if we start to get duplicate subscriptions
  const [client, setClient] = useState(scClient)

  // todo end subscriptions on user login/logout

  return client
}