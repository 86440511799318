import {
    SET_COMPANIES,
    SET_COMPANY_USERS,
    SET_COMPANY,
    SET_COMPANY_LISTING_PAGINATION,
    SET_COMPANY_USER_LISTING_PAGINATION,
    SET_SELECTED_COMPANY
  } from "../../types/superAdmin.json"

const itemsPerPage = 10
const initialState = {
    companiesData: {
        pagination: {
            page: 1,
            totalItems: 0,
            itemsPerPage,
            pageData: {}
        },
        companies: {}
    },
    companyUsersData: {
        selectedCompanyUuid: null,
        pagination: {
            page: 1,
            totalItems: 0,
            itemsPerPage,
            pageData: {}
        },
        companyUsers: {}
    }
}
const setCompanies = (state, companiesData) => {
    const {companies, totalItems, itemsPerPage, page} = companiesData
    const dataState = state.companiesData
    const {pagination} = dataState

    pagination.page = page || pagination.page
    pagination.itemsPerPage = itemsPerPage || pagination.itemsPerPage
    pagination.totalItems = totalItems || pagination.totalItems

    if (page === 1 && pagination.itemsPerPage !== itemsPerPage) {
        pagination.pageData = {
            [pagination.page]: Object.keys(companies)
        }
        dataState.companies = companies
    } else {
        pagination.pageData = {
            ...pagination.pageData,
            [pagination.page]: Object.keys(companies)
        }
        dataState.companies = {...dataState.companies, ...companies} || dataStateCompany
    }

    return state   
}

const setCompanyListingPagination = (state, data) => {
    const {itemsPerPage, page} = data
    state.companiesData.pagination.page = page
    state.companiesData.pagination.itemsPerPage = itemsPerPage
    return state
}

const setCompany = (state, company) => {
    const {uuid} = company
    const companies = state.companiesData.companies
    if (!companies[uuid]) {
        companies[uuid] = {}
    }
    companies[uuid] = company

    return state
}

const setCompanyUsers = (state, companyUsersData) => {
    const {companyUuid, users, page, itemsPerPage, totalItems} = companyUsersData
    const {companyUsers, pagination} = state.companyUsersData
    let companyUsersState = companyUsers[companyUuid]
    if (!companyUsersState) {
        companyUsersState = {}
    }
    
    pagination.page = page || pagination.page
    pagination.itemsPerPage = itemsPerPage || pagination.itemsPerPage
    pagination.totalItems = totalItems || pagination.totalItems
    if (page === 1 && pagination.itemsPerPage !== itemsPerPage) {
        pagination.pageData = {
            [pagination.page]: Object.keys(users)
        }
        companyUsers[companyUuid] = users
    } else {
        pagination.pageData = {
            ...pagination.pageData,
            [pagination.page]: Object.keys(users)
        }
        companyUsers[companyUuid] = {...companyUsers[companyUuid], ...users} || companyUsers[companyUuid]
    }
    return state
}

const setCompanyUserListingPagination = (state, data) => {
    const {itemsPerPage, page} = data
    state.companyUsersData.pagination.page = page
    state.companyUsersData.pagination.itemsPerPage = itemsPerPage
    return state
}

const setSelectedUserCompany = (state, data) => {
    state.companyUsersData.selectedCompanyUuid = data.companyUuid
    return state
}

const superAdminStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANIES:
        return setCompanies(state, action.data)
    case SET_COMPANY:
        return setCompany(state, action.data)
    case SET_COMPANY_USERS:
        return setCompanyUsers(state, action.data)
    case SET_COMPANY_LISTING_PAGINATION:
        return setCompanyListingPagination(state, action.data)
    case SET_COMPANY_USER_LISTING_PAGINATION:
        return setCompanyUserListingPagination(state, action.data)
    case SET_SELECTED_COMPANY:
        return setSelectedUserCompany(state, action.data)
    default:
      return state
  }
}

export default superAdminStateReducer
