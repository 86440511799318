// ** Initial State
const initialState = {
  rowsPerPage: 10,
  usersListingCurrentPage: 1,
  teamsListingCurrentPage: 1,
  companyPagesListingCurrentPage: 1,
  totalItems: 0
}

const paginateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HANDLE_PAGINATION":
      return { ...state, rowsPerPage: action.rowsPerPage, usersListingCurrentPage: action.usersListingCurrentPage, teamsListingCurrentPage: action.teamsListingCurrentPage, companyPagesListingCurrentPage: action.companyPagesListingCurrentPage}
    case "SET_TOTAL_ITEM":
    return {...state, totalItems: action.totalItems}
    default:
      return state
  }
}

export default paginateReducer
