import React, {useEffect, useState} from "react"
import {getUserData} from "../Utils"
import {useDispatch, useSelector} from "react-redux"
import {handleLogin, handleLogout, updateCurrentUser} from "../../redux/actions/auth"
import jwt_decode from "jwt-decode"
import {useSocketCluster} from "../hooks/useSocketCluster"
import {useDomainStorage} from "../hooks/useDomainStorage"
import { userLogout, userProfileUpdated } from "../scTopicAndTypes/types"

export const CurrentUserContext = React.createContext()

export const CurrentUserContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({})
  const [isReady, setReady] = useState(false)
  const userData = useSelector(state => state.auth.userData)
  const dispatch = useDispatch()
  const domainStorage = useDomainStorage()
  const sc = useSocketCluster()

  const checkURLSearchParamToken = () => {
    const params = new URLSearchParams(window.location.search)
    if (params.has('_token')) {
      return domainStorage.setItem('accessToken', params.get('_token'))
    } else {
      return Promise.resolve()
    }
  }

  const logout = async () => {
    // Remove accessToken & userData from domainStorage
    await domainStorage.removeItem('accessToken')
    await domainStorage.removeItem('userData')
    
    localStorage.removeItem('userData')    

    // Remove userData from localStorage and logout
    dispatch(handleLogout())
  }

  const getFromLocalStorage = () => getUserData()

  const getFromDomainStorage = () => {
    return domainStorage.getItem('accessToken').then(token => {
      let decoded = (token ? jwt_decode(token) : undefined)

      if (decoded) {
        decoded = {
          ...decoded,
          accessToken: token
        }
      }

      return decoded
    })
  }

  // Login or logout depending on (localStorage) userData availability and expiry
  useEffect(async () => {
    let userData = getFromLocalStorage()

    // Check if token param is present in query string
    await checkURLSearchParamToken()

    const dsUserData = await getFromDomainStorage()

    if (dsUserData) {
      const roles = userData ? userData.roles : dsUserData.roles
      userData = {...userData, ...dsUserData, roles}
      // Dispatch redux action
      dispatch(handleLogin(userData))
    } else {
      logout()
    }

    // Render children now
    setReady(true)
  }, [])

  // Update currentUser on auth redux state update (login/logout/update actions)
  useEffect(() => {
    setCurrentUser(userData)
  }, [userData])
  
    // Listen to logout event and logout of current app
    useEffect(() => {
      if (userData.uuid) {
        const subscriptionId = sc.subscribe(`/user/${userData.uuid}/message`, data => {
          if (data.type === userLogout) {
            logout()
          }
        })
        const profileUpdateId = sc.subscribe(`/user/${userData.uuid}/message`,
          async (data) => {
            if (data.type === userProfileUpdated) {
              const newUserData = await getFromDomainStorage()
              if (newUserData) {
                dispatch(updateCurrentUser({...currentUser, ...newUserData}))
              }
            }
          }
        )
        return function cleanup() {
          sc.unsubscribe(subscriptionId)
          sc.unsubscribe(profileUpdateId)
        }
      }
    }, [userData.uuid])        

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {isReady && children}
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUser = () => React.useContext(CurrentUserContext)
