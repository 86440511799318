// ** React Imports
import FooterComponent from "./components/footer"
import CompanySwitcher from "./components/menu/vertical-menu/CompanySwitcher"

const SideBarLayout = (props) => {
  // ** Props
  const {children, footer} = props

  return (
    <>
      <div className="main-menu menu-fixed menu-accordion menu-shadow menu-light custom-style-switcher">
        <CompanySwitcher />
      </div>
      <div className="blank-page h-100 custom-style-not-allowed">
        <div className="app-content content">
          <div className="content-wrapper">
            <div className="content-body">{children}</div>
          </div>
        </div>
        <footer className="footer footer-light footer-static bg-white">
          {footer ? footer : <FooterComponent />}
        </footer>
      </div>
    </>
  )
}

export default SideBarLayout
