import { Button } from 'reactstrap'
import somethingWentWrong from '@src/assets/images/svg/somethingWentWrong.svg'

import '@styles/base/pages/page-misc.scss'
import workhubLogo from '@src/assets/images/logo/workhub-latest-logo.png'


const AppErrorPage = () => <div>
  <div className='misc-wrapper flex-column error-page'>
    <a className='brand-logo' to='/' onClick={e => e.preventDefault()}>
      <img src={workhubLogo} alt='logo' className='m-1 logo-height' />
    </a>
    <div className='w-100 d-flex flex-column flex-lg-row align-items-center'>
      <div className='w-33 d-flex justify-content-center align-items-lg-start align-items-center flex-column ml-md-5 m-1 responsive-font text-center text-lg-left'>
        <p className='text-primary mb-2 display-4'>Something went wrong</p>
        <h4 className='mb-2 w-100'>We keep track of these errors, but feel free to contact us if refreshing doesn't fix things.</h4>
        <Button to='/login' color='primary' className='mb-1' onClick={() => window.location.reload(false)}>
          Try again
        </Button>
      </div>
      <div className='img-width'>
        <img className='img-fluid float-right w-100 h-100' src={somethingWentWrong} alt='something went wrong' />
      </div>
    </div>
  </div>
</div>

export default AppErrorPage