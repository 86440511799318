// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import paginate from './paginate'
import JoinRequest from './JoinRequestTimeout'
import company from './company'
import superAdmin from './superAdmin'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  paginate,
  JoinRequest,
  company,
  superAdmin
})

export default rootReducer