// ** React Imports
import { forwardRef } from "react"
import { Icon } from '@iconify/react'

// ** Third Party Components
import Proptypes from "prop-types"
import { Badge } from "reactstrap"
import classnames from "classnames"

const Avatar = forwardRef((props, ref) => {
  // ** Props
  const {
    color,
    className,
    imgClassName,
    initials,
    size,
    badgeUp,
    content,
    icon,
    badgeColor,
    badgeText,
    img,
    imgHeight,
    imgWidth,
    status,
    tag: Tag,
    contentStyles,
    imageFit,
    ...rest
  } = props

  // ** Function to extract initials from content
  const getInitials = (str) => {
    if (str === ": )") {
      return false
    }
    const results = []
    const wordArray = str.split(" ")
    wordArray.forEach((e) => {
      results.push(e[0])
    })
    return results.join("")
  }

  return (
    <Tag
      className={classnames("avatar", {
        [className]: className,
        [`bg-${color}`]: color,
        [`avatar-${size}`]: size
      })}
      {...(contentStyles && {
        style: { width: contentStyles.width, height: contentStyles.height }
      })}
      ref={ref}
      {...rest}
    >
      {img === false || img === undefined ? (
        <span
          className={classnames("avatar-content", {
            "position-relative": badgeUp
          })}
          style={contentStyles}
        >
          {initials && getInitials(content) ? getInitials(content) : content === ": )" ? <Icon style={{color: '#000'}} icon={'mynaui:message'} fontSize='2rem' /> : content}

          {icon ? icon : null}
          {badgeUp ? (
            <Badge
              color={badgeColor ? badgeColor : "primary"}
              className="badge-sm badge-up"
              kpill
            >
              {badgeText ? badgeText : "0"}
            </Badge>
          ) : null}
        </span>
      ) : (
        <img
          className={classnames(imageFit ? "img-scalable" : "", {
            [imgClassName]: imgClassName
          })}
          src={img}
          title={rest?.title ?? ""}
          alt="avatarImg"
          height={imgHeight ? imgHeight : 32}
          width={imgWidth ? imgWidth : 32}
        />
      )}
      {status ? (
        <span
          className={classnames({
            [`avatar-status-${status}`]: status,
            [`avatar-status-${size}`]: size
          })}
        ></span>
      ) : null}
    </Tag>
  )
})

export default Avatar

// ** PropTypes
Avatar.propTypes = {
  imgClassName: Proptypes.string,
  className: Proptypes.string,
  src: Proptypes.string,
  tag: Proptypes.oneOfType([Proptypes.func, Proptypes.string]),
  badgeUp: Proptypes.bool,
  content: Proptypes.string,
  icon: Proptypes.node,
  contentStyles: Proptypes.object,
  badgeText: Proptypes.string,
  imgHeight: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  imgWidth: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  size: Proptypes.oneOf(["sm", "lg", "xl"]),
  status: Proptypes.oneOf(["online", "offline", "away", "busy"]),
  badgeColor: Proptypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "info",
    "warning",
    "dark",
    "light-primary",
    "light-secondary",
    "light-success",
    "light-danger",
    "light-info",
    "light-warning",
    "light-dark"
  ]),
  color: Proptypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "info",
    "warning",
    "dark",
    "light-primary",
    "light-secondary",
    "light-success",
    "light-danger",
    "light-info",
    "light-warning",
    "light-dark"
  ]),
  initials(props) {
    if (props["initials"] && props["content"] === undefined) {
      return new Error("content prop is required with initials prop.")
    }
    if (props["initials"] && typeof props["content"] !== "string") {
      return new Error("content prop must be a string.")
    }
    if (
      typeof props["initials"] !== "boolean" &&
      props["initials"] !== undefined
    ) {
      return new Error("initials must be a boolean!")
    }
  }
}

// ** Default Props
Avatar.defaultProps = {
  tag: "div"
}
