import CircularProgress from "@mui/material/CircularProgress"

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-100">
      <div className="loading">
        <CircularProgress className="mui-spinner" size={50} />
      </div>
    </div>
  )
}

export default SpinnerComponent
