import React, {Fragment} from 'react'
import Avatar from '@components/avatar'
import {Bell, Check, X, AlertTriangle, Info} from 'react-feather'

const types = ['primary', 'success', 'danger', 'warning', 'info']

const iconsMap = {
  primary: Bell,
  success: Check,
  danger: X,
  warning: AlertTriangle,
  info: Info
}
const titles = {
  primary: 'Attention!',
  success: 'Success!',
  danger: 'Error!',
  warning: 'Warning!',
  info: 'Info!'
}

/**
 * Returns a toast of specified type
 * Toast content is injected with closeToast method that can be invoked to close the toast
 * @param type defaults to primary
 * @param title send null or false for no title
 * @param Icon defaults to icon for type
 * @return {JSX.Element}
 * @constructor
 */
export const Toast = ({type, title = undefined, Icon = undefined, ...props}) => {
  const {closeToast, children} = props
  const childrenUpdated = React.Children.map(children, child => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {closeToast})
    }
    return child
  })

  // Set defaults for missing/invalid
  if (!types.includes(type)) type = types[0]
  if (!Icon) Icon = iconsMap[type]
  if (title === undefined) title = titles[type]

  return (
    <Fragment>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color={type} icon={<Icon size={12}/>}/>
          {title && <h6 className='toast-title'>{title}</h6>}
        </div>
        {/*<small className='text-muted'>11 Min Ago</small>*/}
      </div>
      <div className='toastify-body'>
        <span role='img' aria-label='toast-text'>
          {childrenUpdated}
        </span>
      </div>
    </Fragment>
  )
}
/**
 * Returns a toast of primary type
 * Toast content is injected with closeToast method that can be invoked to close the toast
 * @param title send null or false for no title
 * @param Icon defaults to icon for type
 * @return {JSX.Element}
 * @constructor
 */
export const PrimaryToast = props => <Toast type='primary' {...props}/>
/**
 * Returns a toast of success type
 * Toast content is injected with closeToast method that can be invoked to close the toast
 * @param title send null or false for no title
 * @param Icon defaults to icon for type
 * @return {JSX.Element}
 * @constructor
 */
export const SuccessToast = props => <Toast type='success' {...props}/>
/**
 * Returns a toast of error type
 * Toast content is injected with closeToast method that can be invoked to close the toast
 * @param title send null or false for no title
 * @param Icon defaults to icon for type
 * @return {JSX.Element}
 * @constructor
 */
export const ErrorToast = props => <Toast type='danger' {...props}/>
/**
 * Returns a toast of warning type
 * Toast content is injected with closeToast method that can be invoked to close the toast
 * @param title send null or false for no title
 * @param Icon defaults to icon for type
 * @return {JSX.Element}
 * @constructor
 */
export const WarningToast = props => <Toast type='warning' {...props}/>
/**
 * Returns a toast of info type
 * Toast content is injected with closeToast method that can be invoked to close the toast
 * @param title send null or false for no title
 * @param Icon defaults to icon for type
 * @return {JSX.Element}
 * @constructor
 */
export const InfoToast = props => <Toast type='info' {...props}/>
