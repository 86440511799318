import { ENTRYPOINT } from './config'
import createApi from "../createApi"

const api = createApi(ENTRYPOINT)

export default api

api.getUserCompanyProfiles = (uuid, groups = []) => {
  return api.getClient()
    .then(client => client.api_users_user_company_profiles_get_subresourceUserSubresource({
        uuid,
        itemsPerPage: 50,
        'groups[]': groups
      },
      null
    ))
    .then(res => res.data)
}
