import { Button } from "reactstrap"
import CompanyAvatar from "@src/views/components/user/CompanyAvatar"
import React, { useEffect, useState } from "react"
import { useCurrentUser } from "@src/utility/context/CurrentUserContext"
import adminApi from "../../../../../clients/admin/api"
import { useDispatch, useSelector } from "react-redux"
import { updateCurrentUser } from "@store/actions/auth"
import { generateSubdomainUrl, sortCompanies } from "../../../../../utility/Utils"
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip"
import { isEmpty } from "lodash"

const CompanySwitcher = () => {
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()

  const userData = useSelector(state => state.auth.userData)
  const currentUserData = isEmpty(currentUser) ? userData : currentUser

  const [currentUserCompanyProfile] = useState(
    currentUserData?.userCompanies?.[currentUserData.currentCompany]
  )
  const [userCompanyProfiles] = useState(
    currentUserData.userCompanies ? sortCompanies(Object.values(currentUserData.userCompanies)) : undefined
  )

  const handleCompanyUpdate = userCompanyProfile => {
    if (userCompanyProfile.uuid !== currentUserCompanyProfile.uuid) {
      if (process.env.NODE_ENV !== 'development') {
        window.location = generateSubdomainUrl(userCompanyProfile.subDomain, window.location.pathname)
      }
    }
  }

  // Update user company on company switcher change
  useEffect(() => {
    if (currentUserData.uuid && currentUserCompanyProfile) {

      adminApi.getClient()
        .then(client => client.getUserItem(currentUserData.uuid))
        .then(response => {
          dispatch(updateCurrentUser(response.data))
        })
        .catch(error => console.error(error))
    }
  }, [currentUserData.uuid, currentUserCompanyProfile])

  return (<>
    {userCompanyProfiles && currentUserCompanyProfile && (
      <ul className="navbar-nav company-switch">
        <li className="nav-item">

          <Button
            block
            color="none"
            className="d-flex justify-content-start align-items-center btn-gradient-info"
          >
            <CompanyAvatar
              company={currentUserCompanyProfile}
              className="mr-1"
              id={`Tooltip-${currentUserCompanyProfile.uuid}`}
            />
            <span className="company-name text-truncate sidebar-company-line-height">
              {currentUserCompanyProfile.name}
            </span>
            <UncontrolledTooltip
              placement="right"
              target={`Tooltip-${currentUserCompanyProfile.uuid}`}
            >
              {currentUserCompanyProfile.name}
            </UncontrolledTooltip>
          </Button>

          {userCompanyProfiles.length > 1 && userCompanyProfiles.map((userCompanyProfile, index) => {

            if (userCompanyProfile.uuid !== currentUserCompanyProfile.uuid) {
              return (
                <Company
                  handle={handleCompanyUpdate}
                  index={index}
                  userCompanyProfile={userCompanyProfile}
                />
              )
            }
          })
          }
        </li>
      </ul>
    )}
  </>
  )
}

export default CompanySwitcher

const Company = ({ index, handle, userCompanyProfile }) => {
  const { uuid, name } = userCompanyProfile
  return (
    <Button
      key={uuid}
      block
      color="none"
      className="d-flex justify-content-start align-items-center"
      onClick={() => handle(userCompanyProfile)}
      id={`Tooltip-company-${index}`}
    >
      <CompanyAvatar
        key={`${uuid}`}
        company={userCompanyProfile}
        className="mr-1"
      />
      {window.innerWidth < 1200 && <span className="company-name text-truncate sidebar-company-line-height">
        {name}
      </span>}
      <UncontrolledTooltip
        placement="right"
        target={`Tooltip-company-${index}`}
      >
        {name}
      </UncontrolledTooltip>
    </Button>
  )
}