// ** Handle User Login

export const handleLogin = data => {
  return dispatch => {
    dispatch({ type: 'LOGIN', data })
    // Add user to localStorage
    localStorage.setItem('userData', JSON.stringify(data))
  }
}

// ** Handle User Logout
export const handleLogout = () => {
  return dispatch => {
    dispatch({ type: 'LOGOUT' })

    // Remove user from localStorage
    localStorage.removeItem('userData')
  }
}

export const updateCurrentUser = data => {
  return (dispatch, getState) => {
    data = {...getState().auth.userData, ...data}

    dispatch({type: 'CURRENT_USER_UPDATED', data})

    // Update user in localStorage
    localStorage.setItem('userData', JSON.stringify(data))
  }
}
