
const initialState = {
    title: '',
    description:'',
    dashboardUsers:[],
    emailEnabled: true
}
const setDefaultPermissions = (selectedUser) => {
    return { 
        user: selectedUser,
        schedulingEnabled: true,
        emailEnabled: true,
        videoCallEnabled: true
    }
}

const setCompanyDetails = (state, action) => {
    const title = action.payload.title
    const description = action.payload.description
    const dashboardUsers = action.payload.dashboardUsers.map(user => setDefaultPermissions(user)) 
    return {
        ...state,
        title,
        description,
        dashboardUsers 
    }
}

const clearCompanyDetails = (state) => {
    return {
        ...state,
        title: '',
        description: '',
        dashboardUsers: [] 
    }
}
const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMPANY_DETAILS':
        return setCompanyDetails(state, action)
    case 'CLEAR_COMPANY_DETAILS':
        return clearCompanyDetails(state)
    default:
      return state
  }
}

export default companyReducer
