import {useLayoutEffect, useState} from "react"

import jwt from "jsonwebtoken"
import {
  getUserData,
  generateSubdomainUrl,
  getSubDomain,
  reportOnBugSnag
} from "./utility/Utils"
import adminApi from "../src/clients/admin/api"

import {useDomainStorage} from "./utility/hooks/useDomainStorage"
import {useDispatch, useSelector} from "react-redux"
import {
  handleLogin,
  handleLogout,
  updateCurrentUser
} from "./redux/actions/auth"

import {toast, ToastContainer, Slide} from "react-toastify"
import {ErrorToast} from "./views/extensions/toastify/ToastTypes"
import NotAllowed from "./views/pages/misc/NotAllowed"
import SideBarLayout from "./@core/layouts/SideBarLayout"
import BlankLayout from '@layouts/BlankLayout'
import { isWhitelistRoute } from './router/routes/whitelistRoutes'
import { isSuperAdmin } from '@utils'
import AppErrorPage from "./views/pages/misc/AppErrorPage"
import { logoutRoute } from "./router/enums"

const NotAllowedPage = () => <SideBarLayout><NotAllowed /></SideBarLayout>
const InternalServer = () =>  <BlankLayout><AppErrorPage></AppErrorPage></BlankLayout>

const AppPermissions = (props) => {
  const getFromLocalStorage = () => getUserData()
  const userLoginData = getFromLocalStorage()
  const domainStorage = useDomainStorage()
  const dispatch = useDispatch()

  const [profileUpdate, setProfileUpdate] = useState(false)
  const [companyEnable, setCompanyEnable] = useState()
  const showAppErrorPage = useSelector(state => state?.layout?.showAppErrorPage)
  const subDomain = getSubDomain()

  const signupPagePath = "/signup/"
  const isPublicSignupPage = window.location.pathname.indexOf(signupPagePath) !== -1

  const updateUserData = (data, userCompanies) => {
    const userData = getUserData()

    const {roles, company, enabled} = data
    
    dispatch(
        updateCurrentUser({
            ...userData,
            userCompanies,
            currentCompany: company.uuid,
            roles,
            enabled
        })
    )
    setProfileUpdate(true)
  }

  const findEnabledCompany = (userCompanies) => {
    let currentCompany
    if (userCompanies.length > 0) {
      for (const company of userCompanies) {
        const { enabled } = company
        if (enabled) {
          currentCompany = company
          break
        }
      }
    }
    return currentCompany ?? userCompanies[0]
  }

  const storeCompanyData = (data) => {
    let currentUserCompanyProfile
    const userCompanies = {}

    const companyProfiles = data.map((userCompanyProfile) => {
      const {roles, company, enabled} = userCompanyProfile

      userCompanyProfile.roles = roles.map((role) => role.name)

      if (company.subDomain === subDomain) {
        currentUserCompanyProfile = userCompanyProfile
      }
      userCompanies[company.uuid] = {
        ...company,
        roles,
        enabled
      }

      return userCompanyProfile
    })

    if (!currentUserCompanyProfile) {
      currentUserCompanyProfile = findEnabledCompany(companyProfiles)
      const isWhitelistUrl = isWhitelistRoute(window.location.href)
      if (process.env.NODE_ENV !== "development" && !isWhitelistUrl) {
        updateUserData(currentUserCompanyProfile, userCompanies)
        
        if (!isPublicSignupPage) {
          window.location = generateSubdomainUrl(
            currentUserCompanyProfile.company.subDomain,
            window.location.pathname
          )
          return
        }
      }
    }
    
    updateUserData(currentUserCompanyProfile, userCompanies)
    const { enabled } = currentUserCompanyProfile
    setCompanyEnable(!!enabled)
  }

  const decodeAccessToken = (token) => {
    let decoded = token ? jwt.decode(token) : undefined

    if (decoded) {
      decoded = {
        ...decoded,
        accessToken: token
      }
    }

    return decoded
  }

  const checkURLSearchParamToken = () => {
    const params = new URLSearchParams(window.location.search)
    if (params.has("_token")) {
      return domainStorage.setItem("accessToken", params.get("_token"))
    } else {
      return Promise.resolve()
    }
  }

  const getFromDomainStorage = () => {
    return domainStorage.getItem("accessToken").then(decodeAccessToken)
  }

  const logout = async () => {
    await domainStorage.removeItem("accessToken")
    await domainStorage.removeItem("userData")

    localStorage.removeItem("userData")
    dispatch(handleLogout())
  }

  const compareCompanies = (localData, data) => {
    const companyUuids = data.map((userCompanyProfile) => {
      return userCompanyProfile.company.uuid
    })
    if (localData.length === companyUuids.length) {
      for (const itr of localData) {
        if (!companyUuids.includes(itr)) {
          return false
        }
      }
      return true
    } else {
      return false
    }
  }

  const getCompanyProfiles = (userData) => {
    const {uuid, companyUuids} = userData
    console.log('getCompanyProfiles-api_users_user_company_profiles_get_subresourceUserSubresource')
    adminApi
      .getClient()
      .then((client) => client.api_users_user_company_profiles_get_subresourceUserSubresource(
          {
            uuid,
            itemsPerPage: 50,
            "groups[]": ["ucp.roles", "ucp.company"]
          },
          null
        )
      )
      .then((res) => {
        const {data} = res
        if (!compareCompanies(companyUuids, data)) {
          logout()
        } else {
          storeCompanyData(data)
        }
      })
      .catch((error) => {
        reportOnBugSnag('getCompanyProfiles-api_users_user_company_profiles_get_subresourceUserSubresource', error)
        toast.success(
          <ErrorToast>Something went wrong, Try again.</ErrorToast>,
          {transition: Slide, hideProgressBar: true, autoClose: 2000}
        )
      })
  }

  const combineUserData = async () => {
    let userData = getFromLocalStorage()
    const dsUserData = await getFromDomainStorage()
    
    if (dsUserData) {
      const roles = userData ? userData.roles : []
      userData = {...userData, ...dsUserData, roles}
      // Dispatch redux action
      dispatch(handleLogin({...userData}))
      if (isSuperAdmin(userData)) {
        setCompanyEnable(true)
        setProfileUpdate(true)
      } else {
        getCompanyProfiles(userData)
      }
    } else {
      setCompanyEnable(true) // if not found than allow app load it will show error page
      setProfileUpdate(true)
    }
  }
  // Logout from current sub domain if URI has logout pathname
  
  useLayoutEffect(() => {
    async function fetchData() {
      
      if (window.location.pathname === logoutRoute) {
        logout()
      }

      if (userLoginData?.uuid) {
        combineUserData()
      } else {
        await checkURLSearchParamToken() // fetch data from params and update DomainStorage
        combineUserData()
      }
    }
    fetchData()
  }, [])

  return (
    <>
      {showAppErrorPage ? <InternalServer></InternalServer> : <>
      {companyEnable === false && (
        <>
          <ToastContainer newestOnTop />
          <NotAllowedPage/>
        </>
      )}
      {companyEnable && profileUpdate && props.children}</>}
    </>
  )
}

export default AppPermissions
