import { Card, CardBody, Container } from "reactstrap"
import themeConfig from "../../../configs/themeConfig"
import notAuthImg from "@src/assets/images/pages/not-authorized.svg"

const NotAllowed = () => {
  return (
    <Container fluid className="v-stretch p-0 pb-2">
      <Card className="h-100 shadow-none">
        <CardBody>
          <img
            src={themeConfig.app.appWorkhubLogoImage}
            alt="logo"
            className="mw-100"
          />
          <div className="misc-inner p-2 p-sm-3">
            <div className="w-100 text-center">
              <h2 className="mb-1">
                Your account has been disabled. Please contact your admin or try
                switching your Company
              </h2>
            </div>
          </div>
          <div className="misc-inner p-2 p-sm-3">
            <div className="w-100 text-center">
              <img
                className="img-fluid"
                src={notAuthImg}
                alt="Under maintenance page"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Container>
  )
}
export default NotAllowed
