import socketClusterClient from 'socketcluster-client'

/**
 * WHSocketCluster library
 *
 * Including this JS file in an HTML page exposes 'WHSocketCluster' globally. The library auto-initializes so there's no need
 * to create a new object of WHSocketCluster.
 *
 * Library functions:
 * None for now :p
 */
class WHSocketCluster {
  _socket;
  _topics = {};
  _subscriptionHandlers = {};
  _subscriptionIds = {};
  connected = false;

  /**
   * Initializes socket cluster client
   * @private
   */
  constructor () {
    console.log('creating new sc client')

    const {hostname, port, pathname, protocol} = new URL(process.env.REACT_APP_SC_URL)
    const options = {hostname, port, pathname, secure: protocol === 'wss:'}
    
    if (process.env.REACT_APP_BASENAME) {
      options.query = {basename: process.env.REACT_APP_BASENAME}
    }
    this._socket = socketClusterClient.create(options)

    this.connected = true
  }

  /**
   *
   * @param topic
   * @param data
   */
  publish (topic, data) {
    // Publish data to the channel from the socket.
    return this._socket.transmitPublish(topic, JSON.stringify(data))
  }

  /**
   * Adds a subscription handler for event
   * @param string topic the Mercure topic
   * @param callable handler the hander to execute
   * @return string unique subscription id that can be used to remove it
   */
  subscribe (topic, handler) {
    // Add event handler
    if (this._subscriptionHandlers[topic] === undefined) {
      this._subscriptionHandlers[topic] = {}
    }

    const subscriptionId = this._randomId()

    this._subscriptionHandlers[topic][subscriptionId] = handler
    this._subscriptionIds[subscriptionId] = topic

    // Subscribe to new topic
    if (this._topics[topic] === undefined) {
      this._topics[topic] = false // not initialized yet
      this._initSubscription(topic)
    }

    // To unsubscribe
    return subscriptionId
  }

  /**
   * Unsubscribes a subscription handler from a topic
   * @param topic
   * @param subscriptiionId
   */
  unsubscribe (subscriptiionId) {
    const topic = this._subscriptionIds[subscriptiionId]
    if (topic) {
      delete this._subscriptionIds[subscriptiionId]
      delete this._subscriptionHandlers[topic][subscriptiionId]

      // Unsubscribe from channel if no subscription handlers are left
      if (Object.keys(this._subscriptionHandlers[topic]).length === 0) {
        const channel = this._topics[topic]
        channel.close()
        delete this._topics[topic]
      }
    }
  }

  getSocket() {
    return this._socket
  }

  /**
   * Generates a random 10 digit alpha-num string
   * @return {string}
   * @private
   */
  _randomId () {
    const mask = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
      length = 10
    let result = ''
    for (let i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)]
    return result
  }

  /**
   * Subscribes to socket channel and adds internal subscription handler as listener
   * @param topic
   * @private
   */
  _initSubscription (topic) {
    if (this._topics[topic] !== false) {
      return
    }
    (async () => {
      // Subscribe to a channel.
      const channel = this._socket.subscribe(topic)
      this._topics[topic] = channel

      if (channel.state !== channel.SUBSCRIBED) await channel.listener('subscribe').once()
      // channel.state is now 'subscribed'.

      // Attach internal subscription handler
      for await (let data of channel) {
        data = JSON.parse(data)
        console.log('ICSC Received', topic, data)
        Object.values(this._subscriptionHandlers[topic]).forEach(handler => handler(data))
      }
    })()
  }
}

export default new WHSocketCluster()