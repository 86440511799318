import { ROLE_SUPER_ADMIN, SUPER_ADMIN_WRITE } from "../../enums/roles"

// Logged in user ability
export const userAbility = (userData) => {
  const userAbility = [
    {
      action: 'read',
      subject: 'default'
    }
  ]
  if (userData.roles.includes('admin')) {
    userAbility.push({
      action: 'write',
      subject: 'dashboard'
    })
    userAbility.push({
      action: 'write',
      subject: 'team'
    })
    userAbility.push({
      action: 'write',
      subject: 'user'
    })
    userAbility.push({
      action: 'write',
      subject: 'compliance'
    })
    userAbility.push({
      action: 'write',
      subject: 'peopleHR'
    })
    userAbility.push({
      action: 'write',
      subject: 'attendance'
    })
    userAbility.push({
      action: 'write',
      subject: 'stats'
    })
  }
  if (userData.roles.includes(ROLE_SUPER_ADMIN)) {
    userAbility.push({
      action: SUPER_ADMIN_WRITE,
      subject: 'companies'
    })
  }
  return userAbility
}
