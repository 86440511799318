import Bugsnag from '@bugsnag/js'
import { ROLE_SUPER_ADMIN } from '../enums/roles'
import {ErrorToast, SuccessToast} from "../views/extensions/toastify/ToastTypes"
import { toast } from 'react-toastify'
import {Converter} from "showdown"

export const mdToHtml = (val) => {
  if (val) {
    const converter = new Converter()
    return `${converter.makeHtml(val)}`
  }
  return ""
}
  
export  const htmlToMd = (val) => {
  if (val) {
    const converter = new Converter()
    return `${converter.makeMarkdown(val)}`
  }
  return ""
}

export const removeCommentsAndTag = str => str.replace(/<!--[\s\S]*?-->/g, "").replace(/<br>/g, "").replace(/\n\n\n/g, "")

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const isSuperAdmin = (user) => {
  if (user?.roles && user.roles?.length && user.roles.includes(ROLE_SUPER_ADMIN)) {
      return true
  }
  return false
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRoles => {
  if (userRoles instanceof Array) return '/'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const stringToColour = str => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF
    colour += (`00${value.toString(16)}`).substr(-2)
  }
  return colour
}

export const generateUrl = path => {
  return `${window.location.protocol}//${window.location.host}${path}`
}

export const generateSubdomainUrl = (subDomain, path) => {
  return `${process.env.REACT_APP_URL.replace('app', subDomain)}${path}`
}

export const generateIRI = (resource, uuid) => {
  return `/api/${resource}/${uuid}`
}

export const readBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

/**
 * Returns true if str is email, false otherwise
 * @param str
 * @returns {boolean}
 */
export const isValidEmail = str => /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,5}$/.test(str)

export const shuffle = array => {
  let currentIndex = array.length, randomIndex

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

/**
 * Returns object's uuid or undefined
 * @param obj
 * @return string|undefined
 */
export const uuidOrUndefined = obj => obj && obj.uuid

export const redirectWithToken = (location, token) => {
  window.location = `${location}${location.indexOf('?') === -1 ? '?' : '&'}_token=${token}`
}

export const getSubDomain = url => (url ? url : window.location.hostname).split('.')[0]

/**
 * Update scrollbar to recalculate its scrolling position
 */
 export const updateScroll = (scrollBar) => {
  if (scrollBar) {
    scrollBar.updateScroll()
  }
}

export const sortCompanies = (arr) => { return arr.sort((a, b) => Number(b.enabled) - Number(a.enabled)) }


export const singleUserCalendarUrl = (userSeoName) => {
  return `/scheduling/schedule/${userSeoName}`
}

export const companyCalendarUrl = (userSeoName, companyName) => {
  return `/scheduling/schedule/${userSeoName}?_company=${companyName}`
}

export const getVideoRoomUrl = (uuid) => {
  return `/video-room/dashboard/${uuid}`
}

export const getAgentRoomUrl = (dashboardUuid, userUuid) => {
  return `/video-room/agent/${dashboardUuid}/${userUuid}`
}

export const reportOnBugSnag = (message, error) => {
  if (message) {
    Bugsnag.leaveBreadcrumb(message)
  }
  if (error) {
    Bugsnag.notify(error)
  }
}

export const createArrayToN = (n) => {
  return Array.from({ length: n }, (_, index) => index)
}

export const successMessage = (message, duration = 3000) => {
  return toast.success(<SuccessToast title={message} />, {
    hideProgressBar: true,
    autoClose: duration
  })
}

export const errorMessage = (message, duration = 3000) => {
  return toast.error(<ErrorToast title={message} />, {
    hideProgressBar: true,
    autoClose: duration
  })
}

export const isRtlLanguage = () => ((navigator.language || navigator.userLanguage).toLowerCase().includes('ar'))

export const getUserGreetingMessage = (userName) => `Hi ${userName}, How can I help you?`

export const validateEmail = email => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}